.root{
    border-bottom: 1px #eee solid;
    position: fixed;
    width: 100%;
    background-color: #ffffff;
    z-index: 100;
}

.contact{
    color: #5a5a5a;
    font-size: 11px;
    padding: 4px 0;
    border-bottom: 1px #eee solid;
}

.contactContent{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.contact a{
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit
}

.tel svg{
    margin-right: 4px;
}

.content{
    position: relative;
    padding-top: 12px;
    padding-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.socials{
    display: flex;
}

.socials :not(:last-of-type){
    margin-right: 4px;
}

.links :not(:last-of-type){
    margin-right: 12px;
}

.links{
    display: flex;
    align-items: center;
}

.links a {
    display: block;
    color: inherit;
    text-decoration: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 32px;
}

.links a:hover{
    opacity: .7;
}

.burger{
    display: none;
    cursor: pointer;
}

.burger div{
    width: 24px;
    height: 2px;
    background-color: #000;
    transition: transform .2s ease, opacity .2s ease;
}

.burger div:not(:last-of-type){
    margin-bottom: 4px;
}

.root.active .burger div:first-child{
    transform: translateY(6px) rotate(45deg);
}

.root.active .burger div:nth-child(2){
    opacity: 0;
}

.root.active .burger div:last-child{
    transform: translateY(-6px) rotate(-45deg);
}

@media (max-width: 768px){
    .burger{
        display: block;
    }
    .links{
        position: absolute;
        left: 0;
        width: 100%;
        flex-direction: column;
        background-color: #ffffff;
        top: 0;
        padding: 24px;
        margin-top: 56px;
        align-items: stretch;
        text-align: right;
        transform: scaleY(0);
        transform-origin: top;
        opacity: 0;
        pointer-events: none;
        transition: opacity .3s ease, transform .3s ease;
        border-bottom: 1px #eee solid;
    }
    .root.active .links{
        transform: none;
        opacity: 1;
        pointer-events: all;
    }
    .links :not(:last-of-type){
        margin: 0;
    }
}