.Maps {
    position: relative;
    min-height: 600px;
}

.Maps .container{
    pointer-events: none;
    position: absolute;
    top: 50%;
    width: 100%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.Maps .plate {
    z-index: 99;
    pointer-events: all;
    background-color: #fff;
    padding: 32px;
    max-width: 360px;
    border-radius: 13px;
    box-shadow: 0 16px 24px -8px rgba(0,0,0,.14);
}

.Maps .plate h2 {
    margin: 0;
    padding: 0!important;
}

.Maps .plate p {
    font-size: 28px;
    font-weight: bold;
}

.Maps .plate a{
    display: block;
    margin-top: 24px;
    text-decoration: none;
    color: inherit;
    font-weight: bold;
    font-size: 18px;
}


@media (max-width: 768px){
    .Maps{
        min-height: unset;
    }
    .Maps .container{
        position: unset;
        padding: 0;
        top: unset;
        transform: unset;
    }
    .Maps .plate{
        max-width: 100%;
        box-shadow: none;
    }
    .Maps .plate h2 {
        font-size: 18px;
    }
    
    .Maps .plate p {
        font-size: 24px;
    }
}