h1,h2,h3,h4,h5,h6{
    margin: 0;
}

.anchor{
    margin-top: -144px;
    position: absolute;
}

.Cursor--blinking {
    animation: 0.8s blink step-end infinite;
}

@keyframes blink {
    from,
    to {
        color: transparent;
    }
    50% {
        color: inherit;
    }
}

.banner{
    display: block;
    background-color: #d63e2b;
    color: #fff;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='120' height='120' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 0h2v20H9V0zm25.134.84l1.732 1-10 17.32-1.732-1 10-17.32zm-20 20l1.732 1-10 17.32-1.732-1 10-17.32zM58.16 4.134l1 1.732-17.32 10-1-1.732 17.32-10zm-40 40l1 1.732-17.32 10-1-1.732 17.32-10zM80 9v2H60V9h20zM20 69v2H0v-2h20zm79.32-55l-1 1.732-17.32-10L82 4l17.32 10zm-80 80l-1 1.732-17.32-10L2 84l17.32 10zm96.546-75.84l-1.732 1-10-17.32 1.732-1 10 17.32zm-100 100l-1.732 1-10-17.32 1.732-1 10 17.32zM38.16 24.134l1 1.732-17.32 10-1-1.732 17.32-10zM60 29v2H40v-2h20zm19.32 5l-1 1.732-17.32-10L62 24l17.32 10zm16.546 4.16l-1.732 1-10-17.32 1.732-1 10 17.32zM111 40h-2V20h2v20zm3.134.84l1.732 1-10 17.32-1.732-1 10-17.32zM40 49v2H20v-2h20zm19.32 5l-1 1.732-17.32-10L42 44l17.32 10zm16.546 4.16l-1.732 1-10-17.32 1.732-1 10 17.32zM91 60h-2V40h2v20zm3.134.84l1.732 1-10 17.32-1.732-1 10-17.32zm24.026 3.294l1 1.732-17.32 10-1-1.732 17.32-10zM39.32 74l-1 1.732-17.32-10L22 64l17.32 10zm16.546 4.16l-1.732 1-10-17.32 1.732-1 10 17.32zM71 80h-2V60h2v20zm3.134.84l1.732 1-10 17.32-1.732-1 10-17.32zm24.026 3.294l1 1.732-17.32 10-1-1.732 17.32-10zM120 89v2h-20v-2h20zm-84.134 9.16l-1.732 1-10-17.32 1.732-1 10 17.32zM51 100h-2V80h2v20zm3.134.84l1.732 1-10 17.32-1.732-1 10-17.32zm24.026 3.294l1 1.732-17.32 10-1-1.732 17.32-10zM100 109v2H80v-2h20zm19.32 5l-1 1.732-17.32-10 1-1.732 17.32 10zM31 120h-2v-20h2v20z' fill-opacity='.18' fill-rule='evenodd'/%3E%3C/svg%3E");
    padding: 3px 0;
    font-size: 14px;
    text-decoration: none;
}

.banner>div{
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero>div{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 96px;
    min-height: calc(100vh - 96px);
}

.hero{
    position: relative;
}

.test{
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.hero .text{
    text-align: center;
}

.hero .text h2{
    font-size: 32px;
    line-height: 1.1;
    margin-top: 16px;
}

.hero .text h1{
    font-size: 48px;
    line-height: 1.2;
}

.hero .text p{
    max-width: 480px;
    margin: auto;
    font-size: 20px;
    margin-top: 16px;
}

.about{
    background-color: rgb(251, 251, 251);
    padding: 96px 0;
}

.about>div{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.about>div>div{
    width: calc(50% - 32px);
}

.about .photo img{
    max-width: 100%;
    border-radius: 13px;
    box-shadow: 0 16px 24px -8px rgba(0,0,0,.14);
}

.about .text h2{
    font-size: 32px;
}

.about .text p{
    font-size: 20px;
    margin-top: 16px;
}

.partners{
    margin: 0;
    padding: 96px 0;
}

.partners .logos{
    display: flex;
}

.partners .logos img{
    display: block;
    width: 16.666%;
}

.mission{
    padding: 96px 0;
    background-color: rgb(251, 251, 251);
}

.two{
    display: flex;
}

.two-text{
    width: 66%;
}

.two-logo{
    width: 33%;
}

.two-text h1{
    margin-top: 24px;
    font-size: 32px;
    margin-bottom: 32px;
}

.two-text p{
    max-width: 460px;
    font-size: 20px;
}

@media (max-width: 768px){
    .hero .text h2{
        font-size: 24px;
    }
    .hero .text h1{
        font-size: 32px;
    }
    .hero .text p{
        font-size: 16px;
    }
    .about>div{
        display: block;
    }
    .about>div>div{
        width: 100%;
    }
    .about{
        padding: 48px 0;
    }
    .about .photo{
        margin-bottom: 24px;
    }
    .about .text h2{
        font-size: 24px;
    }
    .about .text p{
        font-size: 16px;
    }
    .partners{
        padding: 48px 0;
    }
    .partners .logos{
        flex-wrap: wrap;
    }
    .partners .logos img{
        width: 50%;
    }
    .mission{
        padding: 48px 0;
    }
    .mission h2{
        font-size: 18px;
    }
    .mission h1{
        font-size: 24px;
    }
    .mission p{
        font-size: 16px;
    }
    .two-logo{
        display: none;
    }
    .two-text{
        width: 100%;
    }
}